/* eslint-disable */
import { useState, useEffect, useContext, useReducer, ReactElement, createContext } from 'react';

import { Config, UserManager, TokenManager } from '@forgerock/javascript-sdk';

import history from '../routing/BrowserHistory';
import { CipUserDto, IUser } from '../user/IUser';
import UserReducer, { UserMutationAction } from './UserReducer';
import {
  saveUserHashedId,
  isSameUser,
  getOAuth2CallbackMode,
  OAuth2GrantCallbackMode,
  fetchCodeState,
} from './utils/auth';
import { Persistence } from '../persistence/Persistence';
import { useAPIContext } from '../api/APIContext';
import { useApplicationContext } from '../contexts/ApplicationContext';
import { getPartnerRegionConfig } from '../helpers/PartnerRegionPropertySelector';
import { CIPJWTPayload, decodeToken } from '../helpers/JWT';
import { useWindowLocation } from '../hooks/useWindowLocation';
import { mapCipUser } from '../utils/user';
import { staticConfig } from '../config';

function saveUser(u: IUser): IUser {
  if (u!.userId) Persistence.setStorageEncryptionKey(u!.userId);
  if (isSameUser(u!.userId as string) === false) {
    Persistence.resetUserData();
  }

  saveUserHashedId(u!.userId as string);

  return u;
}

export type OAuthContextResult = {
  success: boolean;
  failure: boolean;
  error?: string;
};

export interface AuthState {
  readonly isAuthenticated: boolean;
  readonly isAuthenticating: boolean;
  readonly idToken?: string;
  readonly accessToken?: string;
  readonly user?: IUser;
  readonly audience?: string;
  dispatch?: ((action: UserMutationAction) => void) | undefined;
}

export interface OAuthContextProviderOptions {
  children?: ReactElement;
  redirectToPath: string;
}

const initialState: AuthState = {
  isAuthenticated: false,
  isAuthenticating: true,
  idToken: undefined,
  accessToken: undefined,
  user: undefined,
  audience: undefined,
};

export const OAuthContext = createContext<AuthState>(initialState);

export const useOAuthContext = (): AuthState => useContext(OAuthContext);

export const OAuthContextProvider = ({ children, redirectToPath }: OAuthContextProviderOptions): JSX.Element => {
  const location = useWindowLocation();
  const [, dispatch] = useReducer(UserReducer, initialState);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [idToken, setIdToken] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [audience, setAudience] = useState('');
  const [user, setUser] = useState<IUser>();

  const { currentPartner, currentPartnerRegion, currentLoyaltyType } = useApplicationContext();
  const { saveUserTokensV2, getCipUser } = useAPIContext();

  useEffect(() => {
    (async () => {
      await useEffectProcessor();
    })();
  }, [redirectToPath, location]);

  const useEffectProcessor = async () => {
    if (!currentPartner || !currentPartnerRegion) {
      return;
    }
    const partnerRegionConfig = getPartnerRegionConfig(currentPartner, currentPartnerRegion);

    setIsAuthenticated(false);
    setIsAuthenticating(false);

    const callbackMode = await getOAuth2CallbackMode(location);
    switch (callbackMode) {
      case OAuth2GrantCallbackMode.CipFlowWithCodeAndState:
      case OAuth2GrantCallbackMode.CipFlow:
        processCIPAuth(partnerRegionConfig);
        break;
      case OAuth2GrantCallbackMode.RejectFlow:
        if (currentPartner === 'amazon-prime') {
          redirectToPrime();
        }
        break;
      default:
        break;
    }
  };

  // redirect to amazon on cip access denied
  const redirectToPrime = () => {
    window.location.replace(staticConfig.amazonPrimeEndpoints.landingPage);
  };

  const processCIPAuth = async (partnerRegionConfig: any) => {
    try {
      if (!partnerRegionConfig.cipConfig) {
        throw new Error('Cannot use this provider');
      }
      Config.set(partnerRegionConfig.cipConfig);

      const { code, state } = fetchCodeState(location);
      let tokens: any = {};
      if (code && state) {
        const params = {
          query: {
            code: code,
            state: state,
          },
        };
        // Get currently logged in user
        tokens = await TokenManager.getTokens(params);
      } else {
        tokens = await TokenManager.getTokens();
      }

      if (!tokens || !tokens.idToken || !tokens.accessToken || !tokens.refreshToken) {
        if (!tokens) {
          throw new Error('CIP tokens not found.');
        }
        if (!tokens.accessToken) {
          throw new Error('accessToken not found.');
        }
        if (!tokens.refreshToken) {
          throw new Error('refreshToken not found.');
        }
        throw new Error('Id not found.');
      }
      processAuthCompletion(tokens);
    } catch (error) {
      const err = encodeURIComponent(error as any);
      history.replace(
        `/500/${currentPartner}/${currentPartnerRegion}?result=${encodeURIComponent(
          'partner ' + currentPartner + ' region ' + currentPartnerRegion + ' Failed to login with ' + err,
        )}`,
      );
    }
  };

  const processAuthCompletion = async (tokens: any) => {
    try {
      setAccessToken(tokens.accessToken);

      setIdToken(tokens.idToken);

      const decodedToken = decodeToken<CIPJWTPayload>(tokens.accessToken);

      if (!decodedToken) {
        throw new Error('Cannot decode CIP access token.');
      }

      setAudience(decodedToken!.aud);
      // tokens only saved for uberpro, since it is having a microsite integrated with uberdriver app
      if (currentPartner === 'uber-pro') {
        await saveUserTokensV2(
          tokens.idToken,
          tokens?.refreshToken,
          decodedToken.socialAccessToken,
          decodedToken.socialRefreshToken,
        );
      }

      UserManager.getCurrentUser()
        .then(async (rslt) => {
          const cipUser = rslt as CipUserDto;
          const userData = await getCipUser(
            tokens.idToken!,
            currentPartner,
            currentLoyaltyType,
            currentPartnerRegion,
            decodedToken?.external_id!,
            tokens.accessToken,
          );

          const mappedUser = mapCipUser(userData.userPii, userData.user, cipUser);

          setUser(saveUser(mappedUser));

          setIsAuthenticated(true);
          history.replace(redirectToPath);
        })
        .catch((error) => {
          const err = encodeURIComponent(error as any);
          history.replace(
            `/500/${currentPartner}/${currentPartnerRegion}?result=${encodeURIComponent(
              'partner ' + currentPartner + 'region ' + currentPartnerRegion + 'Failed to login with ' + err,
            )}`,
          );
        });
    } catch (error) {
      const err = encodeURIComponent(error as any);

      history.replace(
        `/500/${currentPartner}/${currentPartnerRegion}?result=${encodeURIComponent(
          'partner ' + currentPartner + 'region ' + currentPartnerRegion + 'Failed with ' + err,
        )}`,
      );
    }
  };

  return (
    <OAuthContext.Provider
      value={{
        isAuthenticated,
        isAuthenticating,
        idToken,
        accessToken,
        user,
        audience,
        dispatch,
      }}
    >
      {children}
    </OAuthContext.Provider>
  );
};
