import { Persistence } from '../../persistence/Persistence';
import { getHash } from '../../helpers/CryptoHelper';
import { TokenManager } from '@forgerock/javascript-sdk';

export enum OAuth2GrantCallbackMode {
  Unsupported = 0,
  CipFlowWithCodeAndState = 1 << 4, // 10000 = 16
  CipFlow = 1 << 2, // 0100 = 4
  RejectFlow = 1 << 3, // 01000 = 8
}

export type AuthenticationCodeCallbackResult = {
  code?: string;
  error?: string;
};

function isCipAuthCallbackWithCodeAndState(url: Location): boolean {
  if (url === undefined || url.search === undefined || url.search === '') return false;
  return url.search.includes('code=') && url.search.includes('state=');
}

async function isCipAuthCallback(url: Location): Promise<boolean> {
  if ((url && url.pathname.includes('amazon-prime/us')) || url.pathname.includes('uber-pro/us')) {
    await TokenManager.getTokens({
      forceRenew: true,
      login: 'redirect',
    });
    return true;
  }
  return false;
}

function isRejectCallback(url: Location): boolean {
  if (url.pathname.includes('auth/callback')) {
    return url.search.includes('error_description=') && url.search.includes('error=');
  }
  return false;
}

/**
 * Checks the url for callback mode
 * @returns true if has code is param
 */
export async function getOAuth2CallbackMode(location: Location): Promise<OAuth2GrantCallbackMode> {
  if (isCipAuthCallbackWithCodeAndState(location)) {
    return OAuth2GrantCallbackMode.CipFlowWithCodeAndState;
  } else if (await isCipAuthCallback(location)) {
    return OAuth2GrantCallbackMode.CipFlow;
  } else if (isRejectCallback(location)) {
    return OAuth2GrantCallbackMode.RejectFlow;
  }
  return OAuth2GrantCallbackMode.Unsupported;
}

/**
 *
 * Hashes user id and saves
 * @returns true if stored
 */
export function saveUserHashedId(id: string): boolean {
  try {
    const hashed = getHash(id);

    Persistence.setHashedObject(hashed);
    return true;
  } catch (error) {
    return false;
  }
}

/**
 *
 * Checks if user id is same as stored user id (local stoarge)
 * @returns true if same
 */
export function isSameUser(id: string): boolean {
  try {
    const hashed = Persistence.getHashedObject();

    const currentUserIdHash = getHash(id);

    if (hashed) {
      return currentUserIdHash === hashed;
    }
    return false;
  } catch (error) {
    return false;
  }
}

export function fetchCodeState(location: Location): { code: string | null; state: string | null } {
  const url = new URL(location.toString());
  const params = url.searchParams;
  const code: string | null = params.get('code');
  const state: string | null = params.get('state');
  return { code, state };
}
